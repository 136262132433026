@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rcDialogZoomIn {
  0% {
    opacity: 0.75;
    transform: scale(0.95, 0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}
@keyframes rcDialogZoomOut {
  0% {
    transform: scale(1, 1);
  }
  100% {
    opacity: 0.75;
    transform: scale(0.98, 0.98);
  }
}

* {
  box-sizing: border-box;
  outline: 0;
}
body {
  background-color: #222222;
  font-family: Nunito Sans, sans-serif;
  color: #fff;
}
button,
input {
  font-family: Nunito Sans, sans-serif;
}
.scrollbar::-webkit-scrollbar {
  background-color: #222;
  width: 0px;
}
.scrollbar::-webkit-scrollbar-track {
  background-color: #222;
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: #222;
  border-radius: 16px;
}
.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #222;
}
.scrollbar::-webkit-scrollbar-button {
  display: none;
}
.header {
  background-color: #000;
  position: sticky;
  z-index: 100;
  top: 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}
.header-left {
  width: 33%;
  display: flex;
}
.logo {
  display: flex;
  width: 210px;
}
.logo h1 {
  margin: 0;
  margin-left: 12px;
  padding: 0;
  font-weight: bold;
  font-size: 24px;
  font-family: Helvetica;
  position: relative;
  user-select: none;
}
.logo h1 span {
  position: absolute;
  top: -4px;
  left: calc(100% - 8px);
  font-weight: bold;
  font-size: 10px;
  line-height: 11px;
  color: #1371ff;
  white-space: nowrap;
}
.station-selector {
  width: 33%;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  user-select: none;
}
.active-user {
  width: 33%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.social-icon {
  margin-left: 8px;
  font-size: 0;
}
.invite-button {
  margin-left: 16px;
}
.station-created-container {
  text-align: center;
}
.station-created-video {
  background-color: #000;
  width: 480px;
  height: 300px;
  border-radius: 16px;
  margin-bottom: 36px;
  font-size: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 36px;
  position: relative;
}
.station-created-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.create-station-button {
  display: inline-flex;
  align-items: center;
  border: none;
  background-color: transparent;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  cursor: pointer;
}
.create-station-button svg {
  margin-right: 8px;
}
.active-user-profile-image {
  border-radius: 8px;
  height: 29px;
  width: 29px;
  margin-left: 16px;
}
.left-sidebar {
  width: 328px;
  background-color: #111111;
  position: fixed;
  left: 0;
  top: 50px;
  height: calc(100vh - 50px);
  padding: 32px 16px 16px 16px;
  overflow-y: auto;
}
.right-sidebar {
  width: 328px;
  position: fixed;
  right: 0;
  top: 50px;
  height: calc(100vh - 50px);
  padding: 32px 16px 52px 16px;
  overflow: initial;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.main {
  width: 400px;
  margin: 0 auto;
  padding-top: 32px;
  text-align: center;
}
.subheading {
  user-select: none;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.75);
}

.input {
  height: 40px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 0 16px;
  color: #333;
  font-size: 14px;
  border: 0;
}
.search-spotify-input {
  width: 100%;
  margin-bottom: 32px;
  position: sticky;
  top: 0;
  z-index: 100;
}
.input::placeholder {
  color: #333;
}
.recommendations {
  margin-top: 16px;
}
.recommendation-item {
  display: flex;
  align-items: center;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 8px;
}
.recommendation-item-clickable {
  cursor: pointer;
}
.recommendation-item-clickable:hover {
  background-color: #333333;
}
.recommendation-user {
  border-radius: 4px;
  width: 24px;
  height: 24px;
  overflow: hidden;
  margin-right: 8px;
  font-size: 14px;
  line-height: 19px;
  color: #999;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  flex-shrink: 0;
}
.recommendation-user-avatar {
  background-color: #333;
  font-size: 12px;
}
.recommendation-user img {
  width: 100%;
  height: 100%;
}
.recommendation-cover {
  background-color: #333333;
  border-radius: 8px;
  width: 50px;
  height: 50px;
  margin-right: 8px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.recommendation-cover:empty:after {
  content: "?";
  color: #d9d9d9;
  font-weight: bold;
}
.recommendation-title {
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  margin-bottom: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
  overflow: hidden;
}
.recommendation-title:empty {
  background-color: #333;
  width: 120px;
  height: 12px;
  border-radius: 4px;
}
.recommendation-subtitle {
  font-size: 14px;
  line-height: 19px;
  color: #999999;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
  overflow: hidden;
}
.recommendation-subtitle:empty {
  background-color: #333;
  width: 70px;
  height: 12px;
  margin-top: 8px;
  border-radius: 4px;
}
.listener-item {
  margin: 16px 0;
  display: flex;
  align-items: center;
  user-select: none;
}
.listener-item:last-child {
  margin-bottom: 0;
}
.listener-status {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #3dde57;
  margin-right: 12px;
}
.listener-status-placeholder {
  background-color: #333;
}
.listener-image {
  border-radius: 8px;
  height: 40px;
  width: 40px;
  margin-right: 12px;
}
.listener-image-placeholder {
  border-radius: 8px;
  height: 40px;
  width: 40px;
  margin-right: 12px;
  background-color: #333;
  color: #999;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}
.listener-name {
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
}
.listener-name:empty {
  background-color: #333;
  width: 120px;
  height: 12px;
  border-radius: 4px;
}
.playing-now {
  position: relative;
}
.audio-control {
  position: absolute;
  bottom: 64px;
  left: 0;
}
.audio-control img {
  cursor: pointer;
}
.audio-control-slider {
  height: 60px;
  position: absolute;
  bottom: 36px;
  left: 4px;
}
.audio-control-slider.rc-slider-vertical .rc-slider-rail {
  width: 8px;
  border-radius: 4px;
  background-color: #444;
}
.audio-control-slider.rc-slider-vertical .rc-slider-track {
  width: 8px;
  background-color: #1371ff;
}
.audio-control-slider .rc-slider-handle {
  display: none;
}
.audio-control-slider .rc-slider-handle:active {
  box-shadow: none;
}
.playing-now-cover {
  width: 300px;
  height: 300px;
  border-radius: 16px;
  overflow: hidden;
  margin: auto;
  background-color: #333;
  position: relative;
}
.playing-now-cover img {
  width: 100%;
  height: 100%;
}
.now-playing-details {
  margin-top: 12px;
  margin-bottom: 32px;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.now-playing-name {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.now-playing-name:empty {
  background-color: #333;
  width: 100px;
  height: 16px;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
}
.now-playing-artist {
  font-size: 16px;
  line-height: 22px;
}
.now-playing-artist:empty {
  background-color: #333;
  width: 150px;
  height: 12px;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
}
.queue-management {
  margin: auto;
  width: 300px;
  text-align: left;
  position: relative;
}
.queue-item-drag-handler {
  margin-right: 8px;
  width: 24px;
}
.queue-management-tabs {
  text-align: center;
  border-bottom: solid 1px #666666;
  padding-bottom: 8px;
  margin-bottom: 16px;
}
.queue-management-tab-link {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #999999;
  margin: 0 8px;
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  border: none;
}
.queue-management-tab-link.active {
  color: #fff;
}
.button {
  text-decoration: none;
  height: 32px;
  background: #1371ff;
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  border: none;
  display: inline-flex;
  padding: 0 12px;
  align-items: center;
  cursor: pointer;
}
.button-transparent {
  text-decoration: none;
  height: 32px;
  background: transparent;
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
  border: none;
  display: inline-flex;
  padding: 0 12px;
  align-items: center;
  cursor: pointer;
}
.button:enabled:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)),
    #1371ff;
}
.button:enabled:active {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0.05)
    ),
    #1371ff;
}
.button-disabled,
.button:disabled {
  border: 1px solid #999999;
  background-color: transparent;
  color: #999999;
  cursor: default;
}
.button img {
  margin-right: 8px;
}
.teaser {
  text-align: center;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.teaser-heading {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  margin: 24px 0;
}
.activity-log {
  position: relative;
  margin-top: 32px;
  padding-top: 32px;
  border-top: solid 1px #444;
  overflow-y: auto;
  flex-grow: 1;
}
.activity-log * {
  overflow-anchor: none;
}
.activity-log::after {
  content: "";
  display: block;
  overflow-anchor: auto;
  height: 1px;
}
.activity-log-items {
}
.chat-message {
  position: absolute;
  bottom: 16px;
  width: calc(100% - 32px);
  right: 16px;
}
.activity-log-item {
  display: flex;
  margin-bottom: 16px;
}
.activity-log-item-thumb {
  border-radius: 4px;
  width: 24px;
  height: 24px;
  overflow: hidden;
  margin-right: 8px;
  background-color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  text-transform: uppercase;
  font-size: 12px;
  color: #999;
}
.activity-log-item-thumb img {
  width: 100%;
  height: 100%;
}
.message-author {
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #999999;
  margin-right: 8px;
  display: inline;
}
.message-content {
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  display: inline;
}
a {
  color: #1371ff;
}
.love {
  padding: 8px;
  background: #333333;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  position: fixed;
  bottom: 16px;
  left: 16px;
  line-height: 16px;
  font-weight: bold;
  font-size: 12px;
}
.chat-input {
  height: 32px;
  padding: 0 48px 0 12px;
  background-color: #424242;
  border-radius: 8px;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  border: none;
  display: block;
  width: 100%;
}
.chat-input::placeholder {
  color: #fff;
}
.chat-input:focus {
  background-color: #fff;
  color: #111;
}
.chat-input:focus::placeholder {
  color: #111;
}
.chat-message-button {
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: transparent;
  border: 0;
}
.chat-message-button svg {
  fill: #fff;
  opacity: 0;
}
.chat-input:focus + .chat-message-button svg {
  fill: #1371ff;
  opacity: 1;
  cursor: pointer;
}
.playing-now-author {
  width: 300px;
  text-align: left;
  display: flex;
  align-items: center;
  margin: 0 auto 12px auto;
}
.playing-now-author > span {
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
}
.playing-now-author > span:empty {
  background-color: #333;
  width: 150px;
  height: 12px;
  border-radius: 4px;
}
.playing-now-author-image {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: 6px;
  overflow: hidden;
  background-color: #333;
  font-size: 12px;
  color: #999;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}
.playing-now-author-image img {
  width: 100%;
  height: 100%;
}
.queue-item-button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: none;
}
.recommendation-details {
  flex-grow: 1;
  min-width: 0;
}
.recommendation-item:hover .queue-item-button {
  display: block;
}
.mobile-disclaimer {
  display: none;
  position: sticky;
  top: 0;
  background-color: #1371ff;
  padding: 8px 16px;
  z-index: 1000;
}
.toast {
  height: 56px;
  position: absolute;
  background: #333333;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  padding: 0 16px;
}
.toast-icon {
  margin-right: 8px;
}
.added-track-toast {
  position: absolute;
}
.track-cover-notification-wrapper {
  position: absolute;
  overflow: hidden;
}
.track-cover-notification {
  background-color: #333333;
  border-radius: 8px;
  width: 50px;
  height: 50px;
  overflow: hidden;
}
.track-cover-notification img {
  width: 100%;
  height: 100%;
}
.track-notification-wrapper {
  position: absolute;
  left: -64px;
}
.listener-items {
  max-height: 260px;
  overflow-y: auto;
}
.playing-now-cover-spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(34, 34, 34, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}
.playing-now-cover .playing-now-save-backdrop {
  display: none;
  cursor: pointer;
}
.playing-now-cover .playing-now-save-backdrop button {
  background-color: transparent;
  border: none;
  fill: #fff;
  cursor: pointer;
}
.playing-now-cover .playing-now-save-backdrop button.active {
  fill: #ff4f28;
}
.playing-now-cover:hover .playing-now-save-backdrop {
  display: block;
}
.playing-now-save-backdrop {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 58%
  );
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 8px;
  text-align: left;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 48px;
  height: 48px;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 4px solid rgba(255, 255, 255, 0.2);
  border-right: 4px solid rgba(255, 255, 255, 0.2);
  border-bottom: 4px solid rgba(255, 255, 255, 0.2);
  border-left: 4px solid #fff;
  transform: translateZ(0);
  animation: load8 0.5s infinite linear;
}
.loader-small {
  height: 20px;
  width: 20px;
  border-width: 2px;
  margin: 0;
  display: inline-block;
}
.loader-light {
  border-top-color: rgba(255, 255, 255, 0.2);
  border-right-color: rgba(255, 255, 255, 0.2);
  border-bottom-color: rgba(255, 255, 255, 0.2);
  border-left-color: #1371ff;
}

.track-item-history {
  position: relative;
}
.track-item-history .recommendation-item {
  margin-bottom: 0;
}
.track-item-history:before {
  width: 2px;
  background: #333333;
  position: absolute;
  left: 11px;
  top: 0;
  height: 100%;
  display: block;
  content: "";
}
.track-item-history-header {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 6px;
  position: relative;
}
.track-item-history-avatar {
  border-radius: 4px;
  width: 24px;
  height: 24px;
  overflow: hidden;
  margin-right: 8px;
  font-size: 14px;
  line-height: 19px;
  font-size: 12px;
  color: #999;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  flex-shrink: 0;
  background-color: #333;
  text-transform: uppercase;
}
.track-item-history-avatar img {
  width: 100%;
  height: 100%;
}
.track-item-history-content {
  padding-left: 24px;
  padding-bottom: 16px;
}
.landing-page-container {
  width: 1024px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  position: relative;
}
.landing-page-container h1.hero {
  font-weight: bold;
  font-size: 100px;
  line-height: 110px;
}
.button-large {
  font-size: 20px;
  line-height: 27px;
  height: 48px;
  padding: 0 16px;
}
.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}
.background-video video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.background-video:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.7);
  content: "";
  display: block;
}
.landing-page-container .logo h1 {
  font-size: 36px;
}
.landing-header {
  padding-top: 42px;
  margin-bottom: 180px;
  display: flex;
  justify-content: space-between;
}
.link-button {
  background-color: transparent;
  color: #fff;
  display: inline-flex;
  align-items: center;
  border: none;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
}
.link-button .icon {
  margin-right: 8px;
}
.queue-list-actions {
  margin-bottom: 8px;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(19, 113, 255, 0.6);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog {
  background: #ffffff;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.4);
  border-radius: 16px;
  width: 460px;
  margin: 24px;
  max-width: 100%;
  color: #000;
  overflow: hidden;
  text-align: center;
}
.dialog-header {
  height: 180px;
  background-color: #424242;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog-content {
  padding: 40px;
  font-size: 16px;
  line-height: 22px;
}
.dialog-content .title {
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
  color: #000000;
}
.alternative-button {
  background-color: transparent;
  border: none;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #666666;
  cursor: pointer;
}
.social-like-controls {
  position: absolute;
  bottom: 64px;
  right: -38px;
  display: flex;
}
.social-like-controls svg {
  fill: #fff;
  cursor: pointer;
}
.social-like-control {
  margin-right: 16px;
}
.social-like-control,
.social-unlike-control {
  position: relative;
}
.social-like-controls .bar {
  position: absolute;
  width: 8px;
  height: 60px;
  background: #333;
  border-radius: 4px;
  bottom: calc(100% + 4px);
  left: 8px;
}
.social-like-controls .bar .inner {
  position: absolute;
  left: 0;
  bottom: 0;
  background: #cccccc;
  width: 100%;
  border-radius: 4px;
  transition: height 75ms ease-in-out;
}
.social-like-controls .bar.bar-like .inner {
  background: #1371ff;
}
.social-like-controls .bar.bar-unlike .inner {
  background: #ff4f28;
}
.rc-tooltip {
  background: #424242;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  opacity: 1;
}
.tooltip-content {
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  color: #d9d9d9;
}
.tooltip-title {
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  color: #ffffff;
}
.rc-tooltip-inner {
  box-shadow: none;
  background-color: transparent;
}
.rc-tooltip-arrow {
  display: none;
}
.message-tooltip {
  width: 230px;
}
.tooltip-track .recommendation-item {
  margin-top: 4px;
  margin-bottom: 0;
  padding: 0;
}
.tooltip-track .recommendation-cover {
  width: 36px;
  height: 36px;
}
.tooltip-track .recommendation-title {
  margin-bottom: 0;
}
.tooltip-track .recommendation-title,
.tooltip-track .recommendation-subtitle {
  font-size: 13px;
  line-height: 18px;
}
.rc-tooltip-placement-top,
.rc-tooltip-placement-topLeft,
.rc-tooltip-placement-topRight,
.rc-tooltip-placement-bottom,
.rc-tooltip-placement-bottomLeft,
.rc-tooltip-placement-bottomRight {
  padding: 0;
}
.create-station-dialog {
  width: 100%;
  height: 100%;
  margin: 0;
}
.create-station-dialog .rc-dialog-close {
  font-size: 36px;
  opacity: 1;
  color: #666;
}
.create-station-dialog .rc-dialog-content {
  height: 100%;
  border-radius: 0;
  color: #000;
}
.create-station-dialog .rc-dialog-body {
  padding: 70px 0;
}
.create-station-dialog.rc-dialog-zoom-enter,
.create-station-dialog.rc-dialog-zoom-appear {
  /* animation-timing-function: linear; */
  /* animation-duration: 2s; */
}
.create-station-dialog.rc-dialog-zoom-leave {
  animation-timing-function: ease-in;
  animation-duration: 0.1s;
}
.create-station-dialog .container {
  width: 960px;
  margin: 0 auto;
}
.create-station-dialog h1 {
  font-weight: bold;
  font-size: 32px;
  line-height: 44px;
  margin-top: 0;
  margin-bottom: 40px;
}
.create-station-form {
  max-width: 540px;
}
.form-group {
  margin-bottom: 24px;
}
.form-input-wrapper-inline {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 22px;
  color: #333;
}
.form-input-prefix {
  margin-right: 4px;
}
.form-input {
  background: #ffffff;
  border: 1px solid #999999;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 14px;
  line-height: 19px;
  color: #666;
  height: 32px;
  padding: 8px;
  width: 100%;
  display: block;
}
.form-input:focus {
  border: 2px solid #1371ff;
  padding: 7px;
  color: #333;
}
.form-input:disabled {
  background: #d9d9d9;
  border: 1px solid #999999;
  color: #333333;
}
.form-input::placeholder {
  color: #666;
}
textarea.form-input {
  height: auto;
  min-width: 540px;
  min-height: 62px;
}
.form-label {
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  text-transform: uppercase;
  color: #999999;
  display: block;
  margin-bottom: 8px;
}
.form-input-feedback {
  font-size: 14px;
  line-height: 19px;
  color: #333333;
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.form-input-feedback-icon {
  margin-right: 4px;
}
.feedback-loader-container {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}

@media (orientation: portrait) {
  .left-sidebar,
  .right-sidebar,
  .audio-control,
  .station-selector,
  .social-icon,
  .invite-button,
  .social-like-controls {
    display: none;
  }
  .mobile-disclaimer {
    display: block;
  }
  .love {
    right: 16px;
  }
  .main {
    width: 100%;
    padding-bottom: 64px;
  }
  .queue-management {
    width: 100%;
    padding: 0 16px;
  }
  .landing-page-container h1.hero {
    font-size: 36px;
    line-height: 44px;
  }
  .landing-header {
    padding-top: 24px;
  }
  .landing-page-container .logo h1 {
    font-size: 24px;
  }
  .landing-page-container .logo img {
    width: 58px;
  }
}
